<template>
  <div>
    <documentationView :question-list="questionList" redirect="exam-planner-documentation" />
  </div>
</template>

<script>
import documentationView from '@/src/router/layouts/app-document/documentationView.vue'
import EXAM_PLANNER_DATA from '@/src/router/layouts/app-document/exam-planner-documentation/exam-planner.json'
export default {
  name: 'Questions',
  components: {
    documentationView,
  },
  data() {
    return {
      questionList: EXAM_PLANNER_DATA[this.$route.params.id],
    }
  },
}
</script>
